import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import { loadPayrolls } from "src/redux/actions/gusto";
import { addToast } from "src/redux/actions/toasts";
import { createJE, getGustoUserInfo, getPayrollListFilterdateAPI, setDisconnectedGusto } from "src/api";
import { setGustoInfo } from "src/redux/actions/auth";
import { getLongAmoutBlockForLabel, isEmpty } from "src/utils/Common";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Spinner from "src/components/Spinner";
import GustoTable from "src/components/GustoTable";
import moment from "moment";
import ApiConstants from "src/utils/ApiConstant";
import Loader from "src/components/Loader";

function Payroll() {
  const dispatch = useDispatch();
  const history = useHistory();

  const gustoInfo = useSelector((state) => state.auth.gustoInfo);
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const tenant = useSelector((state) => state.auth.user);
  const purchaseOrders = useSelector((state) => state.foodics.payroll);

  const [data, setData] = useState(null);
  const [mappingDialogVisibility, setMappingDialogVisibility] = useState(false);
  const [jeRedirectDialogVisibility, setJeRedirectDialogVisibility] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [loaderMessage, setLoaderMessage] = useState(null);

  const getJEStatus = (jeStatus, bgColor) => {
    return (
      <span
        style={{
          padding: "4px 16px",
          backgroundColor: bgColor,
          borderRadius: "4px"
        }}
      >
        {jeStatus}
      </span>
    );
  }

  const columns = useMemo(
    () => [
      {
        Header: "Period",
        accessor: "pay_period",
        Cell: ({ cell: { row } }) => {
          return <>{`${row.values.pay_period.start_date} - ${row.values.pay_period.end_date}`}</>;
        }
      },
      {
        Header: "Payment Date",
        accessor: "check_date",
      },
      {
        Header: "Processed Date",
        accessor: "processed_date",
      },
      {
        Header: "Total Cost",
        accessor: "totals",
        Cell: ({ cell: { row } }) => {
          return <>{getLongAmoutBlockForLabel(tenant?.currency, row?.values?.totals?.company_debit)}</>;
        }
      },
      {
        Header: "JE Status",
        accessor: "status",
        Cell: ({ cell: { row } }) => {
          if (row.original.je_posted_to_books) {
            return getJEStatus("Posted", "#d5e6e2");
          } else if (row.original.je_created) {
            return getJEStatus("Generated", "#ffe9c5");
          } else {
            return getJEStatus("Pending", "#ffd4d4");
          }
        }
      }
      // Will use later
      , {
        Header: "",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <>
              {!row.original.je_created && <button
                className="btn text-green dk-btn font-weight-bold border-green"
                style={{ opacity: isSetupDone() ? 1 : 0.5, cursor: isSetupDone() ? "pointer" : "not-allowed" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isSetupDone()) {
                    setLoaderMessage("Generating JE... Please wait");
                    syncJE(row)
                  }
                }}
              >
                <span>Generate JE</span>
              </button>}
            </>
          );
        },
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const syncJE = async (row) => {
    try {
      await createJE(row.original.payroll_uuid, tenant.id);
      setJeRedirectDialogVisibility(true);
      getPayrolls();

      ApiConstants.JE_DATE_RAGE = {
        from: ApiConstants.PAYROLL_DATE_RAGE.from,
        to: ApiConstants.PAYROLL_DATE_RAGE.to
      };
      setLoaderMessage(null);
    } catch (err) {
      setLoaderMessage(null);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Unable to Generate JE",
        })
      );
      getPayrolls();
    }
  }

  useEffect(() => {
    setPayrollPayload({
      state: tenant.id,
      from: moment(ApiConstants.PAYROLL_DATE_RAGE.from).format("YYYY-MM-DD"),
      to: moment(ApiConstants.PAYROLL_DATE_RAGE.to).format("YYYY-MM-DD"),
      page: 1,
      limit: 10
    });
  }, []);

  useEffect(() => {
    if (!purchaseOrders?.loading && purchaseOrders?.data) {
      setTotalPages(purchaseOrders.data.totalPages);
      setData(purchaseOrders.data.data || []);
    }
  }, [purchaseOrders]);

  useEffect(() => {
    if (data !== null && tenant) {
      setLoading(false)
    }
  }, [data]);

  useEffect(() => {
    if (deskeraInfo && deskeraInfo.status === 'connected') {
      checkForGusto();
    }
  }, [deskeraInfo]);

  useEffect(() => {
    if (!isEmpty(gustoInfo) && gustoInfo.status === 'connected') {
      ApiConstants.IS_SETUP_DONE = true;
    }
  }, [gustoInfo]);

  const setPayrollPayload = (payload) => {
    ApiConstants.setPayrollPayload({
      ...ApiConstants.PAYROLL_PAYLOAD,
      ...payload
    });

    getPayrolls();
  }

  const getPayrolls = () => {
    dispatch(loadPayrolls(ApiConstants.PAYROLL_PAYLOAD));
  }

  async function checkForGusto() {
    const gusto = await getGustoUserInfo();
    dispatch(setGustoInfo(gusto));
  }

  async function getPayrollListFilterdate(body) {
    const param = {
      state: tenant.id,
      from: body.startDate,
      to: body.endDate
    }

    try {
      const syncResponse = await getPayrollListFilterdateAPI(param)

      setPayrollPayload({
        from: body.startDate,
        to: body.endDate
      });

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job completed",
        })
      );

      if (syncResponse?.newCategoriesAvailable) {
        setMappingDialogVisibility(true);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        await setDisconnectedGusto(tenant.id);
        checkForGusto()
      }

      if (err?.response?.data?.errorMessage) {
        dispatch(
          addToast({
            type: "danger",
            title: "Error",
            message: err?.response?.data?.errorMessage,
          })
        );
      }
      throw err
    }
  }

  const isSetupDone = () => {
    return ApiConstants.IS_SETUP_DONE;
  }

  const getMappingDialog = () => {
    return (
      <Dialog open={mappingDialogVisibility}>
        <DialogTitle>{"New Categories Found"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ textAlign: "justify" }}>
              New Categories were received from Gusto, Please map them with corresponding accounts.
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="d-flex flex-row-reverse p-3">
            <button
              className="btn dk-btn font-weight-bold text-green border-green"
              onClick={(e) => {
                handleRedirect();
              }}
            >
              <span>View Categories</span>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const getJEScreenRedirectDialog = () => {
    return (
      <Dialog open={jeRedirectDialogVisibility}>
        <DialogTitle>{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ textAlign: "justify" }}>
              Journal Entry has been generated, would you like to sync it now?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="d-flex p-3">
            <button
              className="btn dk-btn font-weight-bold text-muted border-gray px-3 mr-3"
              onClick={(e) => {
                handleJERedirectCancel();
              }}
            >
              <span>Cancel</span>
            </button>
            <button
              className="btn dk-btn font-weight-bold text-green border-green px-3"
              onClick={(e) => {
                handleJERedirect();
              }}
            >
              <span>View Journal Entry</span>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const handleJERedirect = () => {
    history.push("/app/je");
  }

  const handleJERedirectCancel = () => {
    setJeRedirectDialogVisibility(false)
  }

  const handleRedirect = () => {
    history.push("/app/categories");
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Payrolls</h3>
      {(!isSetupDone()) && (
        <div class="dk-alert border-bottom alert-warning border-warning p-3 mb-3" role="alert">
          It appears that your current session has expired or you are disconnected.
          In order to resume and continue with the import and processing of your payroll journal entries,
          please take a moment to  connect your Gusto account on the{" "}
          <Link to="/app" className="text-alert-warning">
            <u>Setup</u>
          </Link>{"  "}
          screen.
        </div>
      )}
      {isLoading &&
        <div className="d-flex justify-content-center align-items-center" style={{ height: '20vh' }}>
          <Spinner className="spinner-border-md" />
        </div>
      }
      {loaderMessage && <Loader message={loaderMessage} />}
      {!isLoading &&
        <GustoTable
          data={data}
          columns={columns}
          migrate={getPayrollListFilterdate}
          cart="gusto"
          pages="purchases"
          onDateFilter={(startDate, endDate) => {
            setPayrollPayload({
              from: startDate,
              to: endDate
            });
          }}
          onSearch={(searchText) => {
            setPayrollPayload({
              search: String(searchText).trim() ? String(searchText).trim() : null
            });
          }}
          totalPages={totalPages}
          onPaginationChange={(pageIndex, pageSize) => {
            setPayrollPayload({
              page: pageIndex + 1,
              limit: pageSize
            });
          }}
        />
      }
      {getMappingDialog()}
      {getJEScreenRedirectDialog()}

    </div>
  );
}

export default Payroll;
