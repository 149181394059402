import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getGustoUserInfo, getTenantInfo, setGustoConnection } from "src/api";

import Alert from "src/components/Alert";
import { setCurrentUser } from "src/redux/actions/auth";
import { isEmpty } from "src/utils/Common";

function GustoOAuth() {
  const [status, setStatus] = useState("connecting");
  const history = useHistory();
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.auth.user);
  useEffect(()=>{
    if(isEmpty(tenant)) {
      getTenantDetails()
    }
  },[])
  
  useEffect(() => {
    if(!isEmpty(tenant)) {
      checkForGusto()
    }
  }, [tenant]);

  const getTenantDetails=async()=> {
    try {
      const tenantInfo = await getTenantInfo();
      dispatch(setCurrentUser(tenantInfo));
    } catch (err) {
      // setError("Failed to get tenant info");
    } finally {
      // setLoading(false);
    }
  }
  const checkForGusto = async () => {
    const gusto = await getGustoUserInfo();
    if (gusto && gusto.details) {
      getAddGustoAccountURL(gusto.details);
    } else {
      setStatus("failed");
    }
  };

  const getAddGustoAccountURL=(formValues)=>{
    let windowUrl = new URL(window.location);
    let oauthParams = windowUrl.searchParams;

    let connectionInfo = {
      code: oauthParams.get("code"),
      state: tenant?.id,
      "grant_type": "authorization_code",
      redirect_uri: formValues.redirect_url
    };
    getConnection({...connectionInfo,...formValues})
  }

  const  getConnection = async(formValues)=> {
    try {
      await setGustoConnection(formValues);
      setStatus("connected");
    } catch {
      setStatus("failed");
    } finally {
      setTimeout(() => history.push("/app/help"), 1000);
    }
  }

  

  return status === "connecting" ? (
    <>
    <p>Connecting your Gusto account.</p>
      <div class="spinner-border text-muted" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </>
  ) : status === "connected" ? (
    <Alert type="success">
      Gusto account successfully connected. Redirecting you to Deskera Gusto Integration.
    </Alert>
  ) : (
    <Alert type="danger">
      Failed to connect your Gusto account. Please try authorizing again.
    </Alert>
  );

}

export default GustoOAuth;
