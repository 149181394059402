import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "src/components/Alert";

import GustoTable from "src/components/GustoTable";
import SyncStatus from "src/components/SyncStatus";

function Logs() {
  const purchaseOrders = useSelector((state) => state.foodics.purchaseOrders);
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "supplierName",
      },
      {
        Header: "Email",
        accessor: "supplierEmail",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Deskera Contact Code",
        accessor: "deskeraId",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
            />
          );
        },
        disableGlobalFilter: true,
      },
    ],
    []
  );
  
  function initialiseData() {
    let dat = [];
    console.log(purchaseOrders);
    for (let i = 0; i < purchaseOrders.data.length; i++) {
      let purchaseOrder = purchaseOrders.data[i];
      let bDate = purchaseOrder.businessDate.substring( 0 , 10 );;
      let data1 = {
        supplierEmail : purchaseOrder.supplier.email,
        reason : purchaseOrder.reason,
        syncStatus : purchaseOrder.syncStatus,
        businessDate : bDate,
        code: purchaseOrder.supplier.code,
        supplierName: purchaseOrder.supplier.name
      };
      dat.push(data1);
    }
    setData(dat);
  }

  useEffect(() => {
    initialiseData();
  }, [purchaseOrders]);

  useEffect(()=>{
    console.log(data);
  },[data]);

  const config = useSelector((state) => state.foodics.config);

  return (
    <div className="p-4">
      <h3 className="mb-3">Logs</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced Logs will appear here. Please complete{" "}
          <Link to="/app/foodics" className="text-alert-warning">
            <u>Setup</u>
          </Link>{"  "}
          before continuing.
        </Alert>
      )}
      <GustoTable
        data={data}
        columns={columns}
        cart="foodics"
        pages = "contacts"
      />
    </div>
  );
}

export default Logs;

