import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import SearchLogo from "src/assets/Search";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination
} from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";
import EmptyTray from "src/assets/EmptyTray";
import { useSelector } from "react-redux";

import CloseIcon from "src/assets/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import moment from "moment";

import { isEmpty } from "src/utils/Common";
import BackButton from "src/assets/BackButton";
import NextButton from "src/assets/NextButton";
import ic_calendar from "src/assets/ic_calendar.png";
import ApiConstants from "src/utils/ApiConstant";
import { Switch } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import ic_info_warning from "src/assets/ic_info_warning.svg";

function GustoTable({ data, columns, migrate, cart, pages, subColumns, isSubGrid, filterDate, onDateFilter, onSearch, totalPages = 0, onPaginationChange }) {
  const history = useHistory();

  const refOne = useRef(null);

  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const gustoInfo = useSelector((state) => state.auth.gustoInfo);

  const [value, setValue] = useState();
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [listStartDate, setListStartDate] = useState(ApiConstants.PAYROLL_DATE_RAGE.from);
  const [listEndDate, setListEndDate] = useState(ApiConstants.PAYROLL_DATE_RAGE.to);
  const [syncPayrollStartDate, setSyncPayrollStartDate] = useState(ApiConstants.SYNC_PAYROLL_DATE_RAGE.from);
  const [syncPayrollEndDate, setSyncPayrollEndDate] = useState(ApiConstants.SYNC_PAYROLL_DATE_RAGE.to);
  const [JEStartDate, setJEStartDate] = useState(ApiConstants.JE_DATE_RAGE.from);
  const [JEEndDate, setJEEndDate] = useState(ApiConstants.JE_DATE_RAGE.to);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showSyncPayrollDateRangePicker, setShowSyncPayrollDateRangePicker] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [refreshButton, setRefreshButton] = useState(false);
  const [triggered, setTriggered] = useState(false);
  const [progress, setProgress] = useState(0);
  const [slowProgress, setSlowProgress] = useState(0);
  const [toggleExpandCollapse, setToggleExpandCollapse] = useState(false);
  const [isMoreThan1YearDateSelected, setIsMoreThan1YearDateSelected] = useState(false);

  let tableOptions = {
    columns,
    data,
    initialState: {
      pageSize: 10,
    }
  }

  if (pages === 'purchases' || pages === 'inventory') {
    tableOptions = {
      ...tableOptions,
      manualPagination: true,
      pageCount: totalPages,
      autoResetPage: false
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
    setGlobalFilter,
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onListDateChange = (dates) => {
    const [start, end] = dates;
    setListStartDate(start);
    setListEndDate(end);
    ApiConstants.PAYROLL_DATE_RAGE = {
      from: start,
      to: end
    };

    if (start instanceof Date && end instanceof Date && onDateFilter) {
      setShowDateRangePicker(false);
      onDateFilter(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"));
    }
  };

  const isDiffMoreThanAYear = (startDate, endDate) => {
    // Get the difference in milliseconds between the two dates
    const difference = Math.abs(endDate.getTime() - startDate.getTime());
  
    // Convert milliseconds to years
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365; // Approximation of a year
    const yearsDifference = difference / millisecondsInYear;
  
    return yearsDifference > 1;
  }

  const getCalculatedEndDate = (startDate) => {
    const endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate() - 1);

    return endDate;
  }

  const onSyncPayrollDateChange = (dates) => {
    const [start, end] = dates;
    setSyncPayrollStartDate(start);
    setSyncPayrollEndDate(end);
    ApiConstants.SYNC_PAYROLL_DATE_RAGE = {
      from: start,
      to: end
    };

    if (start instanceof Date && end instanceof Date) {
      setShowSyncPayrollDateRangePicker(false);
      if (isDiffMoreThanAYear(start, end)) {
        setIsMoreThan1YearDateSelected(true);
        setSyncPayrollEndDate(getCalculatedEndDate(start));
      } else {
        setIsMoreThan1YearDateSelected(false);
      }  
    }
  };

  const onJEDateChange = (dates) => {
    const [start, end] = dates;
    setJEStartDate(start);
    setJEEndDate(end);
    ApiConstants.JE_DATE_RAGE = {
      from: start,
      to: end
    };

    if (start instanceof Date && end instanceof Date && onDateFilter) {
      setShowDateRangePicker(false);
      onDateFilter(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
  }, []);

  useEffect(() => {
    if (typeof onPaginationChange === "function") {
      onPaginationChange(pageIndex, pageSize);
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    setToggleExpandCollapse(false);
  }, [page]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSlowProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const customValueRenderer = (selected, _options) => {
    if (pages === "purchases") {
      return selected.length ? (
        <div className="d-flex flex-row overflow-auto">
          {selected.map(({ label }) => {
            return (
              <div
                className="rounded-pill text-bold mr-1 bg-white p-1 border"
                style={{ "border-color": "#d0cfca" }}
              >
                {label}{" "}
                <span
                  className="svg-icon svg-baseline mr-2 svg-gray"
                  onClick={() => {
                    let newSelectedBranches = [];
                    selectedBranches.forEach((br) => {
                      if (br.label !== label) {
                        newSelectedBranches.push(br);
                      }
                    });
                    setSelectedBranches(newSelectedBranches);
                    customValueRenderer(selectedBranches, _options);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        "Select Warehouses"
      );
    }

    return selected.length ? (
      <div className="d-flex flex-row overflow-auto">
        {selected.map(({ label }) => {
          return (
            <div
              className="rounded-pill text-bold mr-1 bg-white p-1 border"
              style={{ "border-color": "#d0cfca" }}
            >
              {label}{" "}
              <span
                className="svg-icon svg-baseline mr-2 svg-gray"
                onClick={() => {
                  let newSelectedBranches = [];
                  selectedBranches.forEach((br) => {
                    if (br.label !== label) {
                      newSelectedBranches.push(br);
                    }
                  });
                  setSelectedBranches(newSelectedBranches);
                  customValueRenderer(selectedBranches, _options);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          );
        })}
      </div>
    ) : (
      "Select Branch"
    );
  };

  const colummsLength = columns.length;

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    onSearch(value);
  }, 200);

  function handleChange(e) {
    e.preventDefault();
    setValue(e.target.value);
    onChange(e.target.value);
  }

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  async function handleSyncSubmit() {
    if (!triggered) {
      setTriggered(true);
      const requestBody = {
        startDate: moment(syncPayrollStartDate).format("YYYY-MM-DD"),
        endDate: moment(syncPayrollEndDate).format("YYYY-MM-DD")
      };

      try {
        await migrate(requestBody);

        if (pages === "purchases") {
          setListStartDate(syncPayrollStartDate);
          setListEndDate(syncPayrollEndDate);
          setIsMoreThan1YearDateSelected(false);
          ApiConstants.PAYROLL_DATE_RAGE = {
            from: syncPayrollStartDate,
            to: syncPayrollEndDate
          };
        }
      } catch (err) {
        if (err.response.status === 401) {
          setDialogVisibility(true);
        }
      }
      setTriggered(false);
      setIsMoreThan1YearDateSelected(false);
    }
  }

  const getSessionDialog = () => {
    return (
      <Dialog open={dialogVisibility} onClose={handleClose}>
        <DialogTitle>{"Session Expired"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ textAlign: "justify" }}>
              Your Gusto session has timed out or expired due to inactivity. Please reconnect to resume your session
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="d-flex flex-row-reverse p-3">
            <button
              className="btn dk-btn font-weight-bold text-green border-green px-3"
              onClick={(e) => {
                handleRedirect();
              }}
            >
              <span>View Setup</span>
            </button>
            <button
              className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
              onClick={(e) => {
                handleClose();
              }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const handleClose = () => {
    setDialogVisibility(false)
  }

  const handleRedirect = () => {
    history.push("/app");
  }

  const loadingProgress = () => {
    let value = progress;
    if (refreshButton) {
      value = slowProgress;
    }
    return (
      <div style={{ paddingBottom: 10 }}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
      </div>
    );
  }

  const handleRowExpand = (rowIndex) => {
    const updatedData = [...page];
    updatedData[rowIndex].isExpanded = !updatedData[rowIndex].isExpanded;
    if (toggleExpandCollapse) {
      setToggleExpandCollapse(updatedData.filter((row) => row.isExpanded).length !== 0);
    } else {
      setToggleExpandCollapse(updatedData.filter((row) => !row.isExpanded).length === 0);
    }
  };

  const renderDateRangePicker = (startDate, endDate, onChange) => {
    return (
      <div ref={refOne} style={{ position: "relative" }}>
        <div className="d-flex"
          style={{
            zIndex: 99,
            position: "absolute",
            top: pages === "inventory" ? "18px" : "1px",
            right: "7px"
          }}>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      </div>
    );
  }

  const getDatePickerTitle = (startDate, endDate) => {
    return (
      <span>
        <img src={ic_calendar} alt="" className="mr-2" style={{ height: "18px", width: "18px", opacity: 0.5, marginTop: "-3px" }} />
        {`${moment(startDate).format("YYYY-MM-DD")} - ${endDate instanceof Date ? moment(endDate).format("YYYY-MM-DD") : "_____-___-___"}`}
      </span>
    );
  }
  const isSetupDone = () => {
    return (deskeraInfo.status === "connected") ? ((!isEmpty(gustoInfo) && gustoInfo.status === 'connected')) : false
  }

  const handleClickOutside = (e) => {
    if (refOne.current != null && !refOne.current.contains(e.target)) {
      setShowDateRangePicker(false)
      setShowSyncPayrollDateRangePicker(false)
    }
  }

  const showExpandCollapseArrow = (isExpanded) => {
    return (
      <span className="svg-icon mr-1" style={{ transform: isExpanded ? "rotate(270deg)" : "rotate(0deg)" }}>
        {isExpanded ? <BackButton /> : <NextButton />}
      </span>
    )
  }

  return (
    <>
      {/* {syncConfigOpen && foodicsSyncConfig()} */}
      {pages === 'purchases' && isSetupDone() && (
        <div
          className={`card w-650 mb-3 ${!isSubGrid ? "mb-3" : ""}`}
          style={{
            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.15)",
            border: "none"
          }}
        >
          {
            <div className="d-flex bg-white align-items-center" style={{ padding: "0.75rem", border: "1px solid lightgray", borderRadius: 4 }}>
              <div className="text-muted" style={{ maxWidth: "calc(100% - 400px)" }}>
                If you have recently completed the processing of Gusto payrolls,
                we recommend utilizing the sync payroll functionality to seamlessly import the payroll data into this system
                and post payroll journal entries.
              </div>
              <div className="d-flex align-items-center ml-auto">
                <div className="mr-1 d-flex align-items-center">
                  {isMoreThan1YearDateSelected && (
                    <div className="mr-1">
                      <img
                        className="icon-md"
                        src={ic_info_warning}
                        alt=""
                        data-tip="Note: Gusto allows to sync payrolls for a max time window of 1 year, end date reset to 1 year from start date."
                      />
                      <ReactTooltip className="tooltipClass" effect="solid" />
                    </div>
                  )}
                  <div className="btn border-gray text-muted fw-500 mr-1"
                    ref={refOne}
                    onClick={(e) => {
                      setShowSyncPayrollDateRangePicker(!showSyncPayrollDateRangePicker)
                    }}
                  >
                    {getDatePickerTitle(syncPayrollStartDate, syncPayrollEndDate)}
                  </div>
                  {showSyncPayrollDateRangePicker && renderDateRangePicker(syncPayrollStartDate, syncPayrollEndDate, onSyncPayrollDateChange)}
                </div>
                <div>
                  <button
                    className="btn px-3 dk-btn font-weight-bold text-green border-green"
                    disabled={triggered || !isSetupDone()}
                    onClick={(e) => {
                      handleSyncSubmit();
                    }}
                  >
                    <span>Sync Payrolls</span>
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      )}

      <div className={`d-flex flex-row  ${!isSubGrid ? "mb-3" : ""}`} style={{ justifyContent: "right" }}>
        {!isSubGrid && <form className="form-inline">
          {pages === 'purchases' && (
            <div>
              <div
                className="btn border-gray text-muted fw-500 mr-2"
                ref={refOne}
                onClick={(e) => {
                  setShowDateRangePicker(!showDateRangePicker)
                }}
              >
                {getDatePickerTitle(listStartDate, listEndDate)}
              </div>
              {showDateRangePicker && renderDateRangePicker(listStartDate, listEndDate, onListDateChange)}
            </div>
          )}
          {pages === 'inventory' && (
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <span className="text-muted">Expand all</span>
                <Switch
                  color="primary"
                  checked={toggleExpandCollapse}
                  onChange={() => {
                    [...page].forEach((row) => row.isExpanded = !toggleExpandCollapse);
                    setToggleExpandCollapse(!toggleExpandCollapse);
                  }}
                />
              </div>
              <div
                className="btn border-gray text-muted fw-500 mr-2"
                ref={refOne}
                onClick={(e) => {
                  setShowDateRangePicker(!showDateRangePicker)
                }}
              >
                {getDatePickerTitle(JEStartDate, JEEndDate)}
              </div>
              {showDateRangePicker && renderDateRangePicker(JEStartDate, JEEndDate, onJEDateChange)}
            </div>
          )}
          {(pages === 'purchases' || pages === 'inventory') && (
            <div className="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light">
                  <span className="svg-icon svg-disabled">
                    <SearchLogo />
                  </span>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={handleChange}
                value={value}
              />
            </div>
          )}
        </form>}
      </div>
      {(triggered || refreshButton) && loadingProgress()}
      <div className="card dk-card" style={{ overflowX: "auto" }}>
        <table
          className="table m-0 dk-table-hover dk-table"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                  >
                    <span className="dk-table-header-content mr-2">
                      {column.render("Header")}{" "}
                    </span>
                    {/* <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>  */}
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.length === 0 && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className="d-flex flex-column align-items-center">
                    <span
                      className="mt-3 svg-disabled"
                      style={{ width: "50px", height: "50px" }}
                    >
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">
                      No records were found.
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRowExpand(index)}
                    className={subColumns ? 'cursor-pointer' : ''}
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                      >
                        {index === 0 && subColumns && showExpandCollapseArrow(row.isExpanded)}
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                  {row.isExpanded && subColumns && (
                    <tr>
                      <td className="shadow-inside" colSpan={colummsLength}>
                        <div>
                          <GustoTable data={row.original.lineItems} columns={subColumns} pages="inventory" isSubGrid={true} />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {((!isSubGrid && page?.length !== 0) || (isSubGrid && data?.length > 10)) && <div className="d-flex flex-row-reverse align-items-center mt-3">
        <div className="d-flex flex-row align-items-center">
          <span className="mr-2">Max rows per page:</span>
          <Select
            readOnly={true}
            placeholder="Page"
            className="page-selector"
            options={pageSizeOptions}
            value={pageSizeOptions.filter(
              (option) => option.value === pageSize
            )}
            menuPlacement="auto"
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-row align-items-center mr-2">
          <button
            className="btn mr-2"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button
            className="btn mr-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className="mr-2">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button
            className="btn mr-2"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline",
                canNextPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <button
            className="btn"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline",
                canNextPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>}
      {getSessionDialog()}
    </>
  );
}

export default GustoTable;
