import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function Loader(props) {
    return (
        <Dialog open={true}>
            <DialogContent className="py-2 px-4">
                {props.message}
            </DialogContent>
        </Dialog>
    );
}

export default Loader;
