import { NavLink, Redirect, Route, Switch, useLocation } from "react-router-dom";
import Header from "src/components/Header";
import ToastList from "src/components/ToastList";
import FoodicsLogo from "src/assets/FoodicsLogo";
import Foodics from "src/pages/Gusto";
import "src/index.css";
import "src/styles/index.scss";

function Dashboard() {
  const location = useLocation();
  let sidebarItems = [
    {
      logo: <FoodicsLogo />,
      lightLogo: <FoodicsLogo />,
      href: "/app",
      component: <Foodics />
    }
  ];

  return (
    <div className="d-flex flex-column overflow-hidden wh-100 vh-100">
      <Header />
      <ToastList />
      <div className="d-flex flex-row overflow-auto vh-100 wh-100">
        <Switch>
          <Route exact path="/app/foodics">
            <Redirect to="/app" />
          </Route>
          {sidebarItems.map((item) => (
            <Route path={item.href}>{item.component}</Route>
          ))}
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
