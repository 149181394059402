import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getCOAccount, getCOAMapping, getGustoUserInfo, saveCOAMapping } from "src/api";
import Loader from "src/components/Loader";
import Spinner from "src/components/Spinner";
import { setGustoInfo } from "src/redux/actions/auth";
import { addToast } from "src/redux/actions/toasts";
import ApiConstants from "src/utils/ApiConstant";
import { isEmpty } from "src/utils/Common";

function CategoryMapping(props) {
  const dispatch = useDispatch();

  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const gustoInfo = useSelector((state) => state.auth.gustoInfo);

  const [mapping, setMapping] = useState({})
  const [category, setCategory] = useState(props?.data?.categoryData || []);
  const [expenseOptions, setExpenseOptions] = useState([]);
  const [liabilityOptions, setLiabilityOptions] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [accountChangeState, setAccountChangeState] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState(null);

  useEffect(() => {
    getCOAccountData()
  }, [])

  useEffect(() => {
    if (!isEmpty(expenseOptions) && !isEmpty(liabilityOptions)) {
      let categoryList = [...category];
      categoryList = categoryList.map((c) => {
        let existingMappingData = {}
        if (mapping && mapping.mappings) {
          existingMappingData = mapping.mappings.find(m => m.gustoCategoryId === c.id);

        }
        return {
          ...c,
          debitAccountCode: (existingMappingData && existingMappingData.debitAccountCode) ? expenseOptions.find(e => e.accountCode === existingMappingData.debitAccountCode) : {},
          creditAccountCode: (existingMappingData && existingMappingData.creditAccountCode) ? liabilityOptions.find(e => e.accountCode === existingMappingData.creditAccountCode) : {},
        };
      });
      setCategory(categoryList)
    }
  }, [expenseOptions, liabilityOptions]);

  useEffect(() => {
    if (deskeraInfo && deskeraInfo.status === 'connected') {
      checkForGusto();
    }
  }, [deskeraInfo]);

  useEffect(() => {
    if (!isEmpty(gustoInfo) && gustoInfo.status === 'connected') {
      ApiConstants.IS_SETUP_DONE = true;
    }
  }, [gustoInfo]);

  async function checkForGusto() {
    const gusto = await getGustoUserInfo();
    dispatch(setGustoInfo(gusto));
  }

  const isSetupDone = () => {
    return ApiConstants.IS_SETUP_DONE;
  }

  const getCOAccountData = async () => {
    const content = await getCOAccount();
    const mapp = await getCOAMapping();
    setMapping(mapp);
    setExpenseOptions(content?.data?.content?.filter((x) => x.accountGroup === 'Expenses')?.map(e => { return { ...e, label: e.name, value: e.accountCode } }));
    setLiabilityOptions(content?.data?.content?.filter((x) => x.accountGroup === 'Current Liabilities')?.map(e => { return { ...e, label: e.name, value: e.accountCode } }));
    setLoading(false)
  }
  const save = async () => {
    setLoaderMessage("Saving mapping... Please wait");
    try {
      let mappings = category.map(c => {
        return {
          creditAccountCode: c.creditAccountCode.accountCode,
          debitAccountCode: c.debitAccountCode.accountCode,
          gustoCategoryId: c.id,
          payType: c.type
        }
      })
      let payload = {
        mappings: mappings
      }
      if (mapping && !isEmpty(mapping.mappings)) {
        payload['id'] = mapping.id
      }
      const res = await saveCOAMapping(payload)
      setMapping(res);
      setDisableSaveBtn(true);
      setLoaderMessage(null);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Mappings has been saved",
        })
      );
    } catch(err) {
      setLoaderMessage(null);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Internal server error",
        })
      )
    }
  }
  const categoryListView = (payType) => {
    return category.map((c, i) => {
      let isVisible = false;
      switch (payType) {
        case 'earning':
          if (c.type === 'Earnings') {
            isVisible = true;
          }
          break;
        case 'employeeDeduction':
          if (c.type === 'Employee Deductions') {
            isVisible = true;
          }
          break;
        case 'employerContribution':
          if (c.type === 'Employer Contributions') {
            isVisible = true;
          }
          break;
      }
      if (isVisible) {
        return (
          <div className="w-100 d-flex py-2">
            <div className="w-30-p ml-4 py-1">{c.name}</div>
            <div className="w-30-p ml-4">
              <Select
                options={expenseOptions} // Options to display in the dropdown
                onChange={(e) => {
                  let categoryValue = [...category]
                  categoryValue[i]['debitAccountCode'] = e;
                  setCategory(categoryValue)
                  setDisableSaveBtn(false);
                  setAccountChangeState({
                    payType: payType,
                    accType: "expense",
                    account: e
                  });
                }}

                value={c.debitAccountCode || null}
              />
            </div>
            <div className="w-30-p ml-4">
              <Select
                options={liabilityOptions}
                onChange={(e) => {
                  let categoryVal = [...category]
                  categoryVal[i]['creditAccountCode'] = e;
                  setCategory(categoryVal)
                  setDisableSaveBtn(false);
                  setAccountChangeState({
                    payType: payType,
                    accType: "liability",
                    account: e
                  });
                }}
                value={c.creditAccountCode || null}
              />
            </div>
          </div>
        );
      }
    })
  }

  const handleApplyToAll = (isExpense) => {
    if (Array.isArray(category)) {
      const categories = [...category];
      categories.forEach((c) => {
        let payType;
        switch (accountChangeState.payType) {
          case 'earning':
            if (c.type === 'Earnings') {
              payType = 'Earnings';
            }
            break;
          case 'employeeDeduction':
            if (c.type === 'Employee Deductions') {
              payType = 'Employee Deductions';
            }
            break;
          case 'employerContribution':
            if (c.type === 'Employer Contributions') {
              payType = 'Employer Contributions';
            }
            break;
          default:
            break;
        }

        if (c.type === payType) {
          if (isExpense) {
            c.debitAccountCode = accountChangeState.account; 
          } else {
            c.creditAccountCode = accountChangeState.account; 
          }
        }
      });
      setCategory(categories);
      setAccountChangeState(null);
    };
  }

  const getApplyToAll = (isExpense) => {
    return (
      <div className="ml-auto">
        <button
          className="text-green"
          style={{
            fontWeight: 600,
            padding: 0,
            border: "none",
            background: "none",
            textDecoration: "underline 2px solid #006641"
          }}
          onClick={(e) => {
            handleApplyToAll(isExpense);
          }}>
          Apply to all
        </button>
      </div>
    );
  }

  const mappingView = [
    {
      type: 'earning',
      pay: 'Pay Type',
      expense: 'Expense Account',
      liability: 'Liability Account'
    },
    {
      type: 'employeeDeduction',
      pay: 'Pay Type',
      expense: 'Expense Account',
      liability: 'Liability Account'
    },
    {
      type: 'employerContribution',
      pay: 'Pay Type',
      expense: 'Expense Account',
      liability: 'Liability Account'
    }
  ]
  return (
    <>
      <div className="card w-650 mb-3" style={{ boxShadow: "0 1px 2px rgba(0, 0, 0, 0.15)", border: "none" }} >
        <div className="d-flex bg-white align-items-center" style={{ padding: "0.75rem", border: "1px solid lightgray", borderRadius: 4 }}>
          <div className="text-muted" style={{ maxWidth: "calc(100% - 400px)" }}>
            Map payroll pay types to appropriate expense and liability accounts
          </div>
          <div className="ml-auto">
            <button
              className="btn text-success px-4 dk-btn font-weight-bold border border-success"
              onClick={(e) => save()}
              disabled={disableSaveBtn || !isSetupDone()}
            >
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
      {(isLoading) && <div className="d-flex justify-content-center align-items-center" style={{ height: '20vh' }}>
        <Spinner className="spinner-border-md" />
      </div>}
      {loaderMessage && <Loader message={loaderMessage} />}
      {!isLoading && <>
        {mappingView.map(mapping => {
          return (
            <div
              className="mt-3 p-4 bg-white"
              style={{ border: "1px solid lightgray", borderRadius: 4 }}
              key={mapping.type}
            >
              <div className="w-100 d-flex pb-4">
                <div className="w-30-p ml-4 fw-600">{mapping.pay}</div>
                <div className="w-30-p ml-4 fw-600">
                  <div className="d-flex">
                    <div>{mapping.expense}</div>
                    {accountChangeState?.payType === mapping.type && accountChangeState?.accType === "expense" && getApplyToAll(true)}
                  </div>
                </div>
                <div className="w-30-p ml-4 fw-600">
                  <div className="d-flex">
                    <div>{mapping.liability}</div>
                    {accountChangeState?.payType === mapping.type && accountChangeState?.accType === "liability" && getApplyToAll(false)}
                  </div>
                </div>
              </div>
              {categoryListView(mapping.type)}
            </div>)
        })}
      </>}

    </>
  );
}
export default CategoryMapping;
